/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'swiper/scss';
@import 'swiper/scss/grid';
// @import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

/* --- Start customize bootstrap --- */
$font-family-base: 'Kanit';

@import 'bootstrap';

/* --- End customize bootstrap --- */

$cp-primary-linear-gradient-1: linear-gradient(
  180deg,
  #29e3eb 0%,
  #00b9b9 87.12%
);
$cp-primary-spend-linear-gradient-1: linear-gradient(
  180deg,
  #fcb224 0%,
  #ef8f00 87.12%
);
$cp-primary-linear-gradient-leaderboard: linear-gradient(
  180deg,
  #26beff 0%,
  #1976e0 100%
);
$cp-primary-1: #2b77ff;
$cp-primary-2: #2262d8;
$cp-primary-3: #0943ad;
$cp-primary-4: #027a82;
$cp-primary-4-spend: #da6900;
$cp-primary-leaderboard: #1652c1;
$cp-primary-black: #212121;
$cp-primary-gray: #838383;
$cp-primary-darkgray: #565656;

$cp-btn-disabled-1: #e0e0e0;
$cp-btn-disabled-2: #cacaca;

$cp-disabled-linear-gradient-1: linear-gradient(
  180deg,
  #e9e9e9 0%,
  #e0e0e0 100%
);

$s18-20: clamp(18px, 5.34vw, 20px);
$s16-18: clamp(16px, 4.8vw, 18px);
$s14-16: clamp(14px, 4.27vw, 16px);
$s12-14: clamp(12px, 3.6vw, 14px);
$s10-12: clamp(10px, 3.2vw, 12px);
$s48-64: clamp(48px, 17.1vw, 64px);

html {
  overscroll-behavior-x: none;
}

body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  overscroll-behavior-x: none;
  font-size: 16px;
  font-size: $s14-16;

  &.noscroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  pointer-events: none;
}

.box-btn {
  border: 4px solid #ffffff;
  padding-bottom: 8px;
  border-radius: 24px;
  box-shadow: 0px 4px 6px rgb(0 48 51 / 24%);
  height: $s48-64;
  margin: -15px;
}

.cp-btn {
  &-primary {
    height: 48px;
    height: clamp(40px, 12.83vw, 48px);
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-size: clamp(16px, 4.8vw, 18px);
    font-weight: 600;
    color: #ffffff;
    background: $cp-primary-linear-gradient-1;
    border: none;
    box-shadow: 0px 8px 0px $cp-primary-4 !important;
    box-shadow: 0px clamp(6px, 2.135vw, 8px) 0px $cp-primary-4 !important;
    border-radius: 24px;
    transition: transform 0ms;

    &:focus,
    &:hover,
    &:active {
      color: #ffffff !important;
    }

    &:active {
      transform: translateY(8px);
    }

    &.padding-4-imp {
      padding-top: 4px !important;
    }

    .flare {
      position: absolute;
      left: 12px;
      top: 8px;
      width: 8px;
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      transform: rotate(45deg);
      border-radius: 50%;
    }
  }

  &-primary-spend {
    height: 48px;
    height: clamp(40px, 12.83vw, 48px);
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-size: clamp(16px, 4.8vw, 18px);
    font-weight: 600;
    color: #ffffff;
    background: $cp-primary-spend-linear-gradient-1;
    border: none;
    box-shadow: 0px 8px 0px $cp-primary-4-spend !important;
    box-shadow: 0px clamp(6px, 2.135vw, 8px) 0px $cp-primary-4-spend !important;
    border-radius: 24px;
    transition: transform 0ms;

    &:focus,
    &:hover,
    &:active {
      color: #ffffff !important;
    }

    &:active {
      transform: translateY(8px);
    }

    .flare {
      position: absolute;
      left: 12px;
      top: 8px;
      width: 8px;
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      transform: rotate(45deg);
      border-radius: 50%;
    }
  }

  &-primary-leaderboard {
    height: 48px;
    height: clamp(40px, 12.83vw, 48px);
    padding-top: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-size: clamp(16px, 4.8vw, 18px);
    font-weight: 600;
    color: #ffffff;
    background: $cp-primary-linear-gradient-leaderboard;
    border: none;
    box-shadow: 0px 8px 0px $cp-primary-leaderboard !important;
    box-shadow: 0px clamp(6px, 2.135vw, 8px) 0px $cp-primary-leaderboard !important;
    border-radius: 24px;
    transition: transform 0ms;

    &:focus,
    &:hover,
    &:active {
      color: #ffffff !important;
    }

    &:active {
      transform: translateY(8px);
    }

    .flare {
      position: absolute;
      left: 12px;
      top: 8px;
      width: 8px;
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      transform: rotate(45deg);
      border-radius: 50%;
    }
  }

  &-link {
    &:focus,
    &:hover,
    &:active {
      outline: 0;
      // color: #ffffff;
      // background: $cp-primary-linear-gradient-1;
      // border: none;
      // box-shadow: 0px 8px 0px $cp-primary-3;
      box-shadow: none;
    }

    &.link-term {
      font-size: 16px;
      font-size: $s14-16;
      font-weight: 600;
      color: $cp-primary-1;
    }
  }

  &-circle {
    position: sticky;
    width: 8px;
    height: 12px;
    left: 16.49px;
    top: 8px;

    background: rgba(255, 255, 255, 0.4);
    mix-blend-mode: screen;
    transform: rotate(45deg);
    border-radius: 50%;
  }

  &-secondary {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    font-size: $s14-16;
    line-height: 24px;
    height: 64px;
    height: $s48-64;
    background-color: #ffffff;
    padding: $s10-12 $s14-16;
  }

  &-secondary-blue {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    gap: 8px;
    height: 32px;
    background: #ffffff;
    border: 1px solid $cp-primary-1;
    border-radius: 8px;
    font-weight: 600;
    color: $cp-primary-1;

    img {
      width: 18px;
      height: 18px;
    }

    &:focus,
    &:hover,
    &:active {
      display: flex;
      align-items: center;
      padding: 4px 12px;
      gap: 8px;
      height: 32px;
      background: #ffffff;
      border: 1px solid $cp-primary-1 !important;
      border-radius: 8px;
      font-weight: 600;
      color: $cp-primary-1 !important;
    }
  }

  &:disabled {
    color: #ffffff;
    background: $cp-disabled-linear-gradient-1;
    box-shadow: 0px 8px 0px $cp-btn-disabled-2 !important;
    opacity: 1;

    .flare {
      position: absolute;
      left: 12px;
      top: 8px;
      width: 8px;
      height: 12px;
      background: rgba(255, 255, 255, 0.4);
      transform: rotate(45deg);
      border-radius: 50%;
    }
  }

  &-wrap-text-icon {
    height: 32px;
    margin-top: 24px;
    text-align: center;
    border-radius: 100px;
    background-color: #edf5ff;

    .icon-left {
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 8px;
    }

    span {
      color: #2b77ff;
      font-weight: 400;
      font-size: 14px;
      font-size: clamp(12px, 3.74vw, 14px);
    }

    .icon-right {
      margin-left: 7px;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.cp-card {
  position: relative;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;

  @media (max-width: 574px) {
    height: calc((100vw * 256) / 375);
  }
  @media (min-width: 574px) {
    height: 16rem;
  }

  .cp-card-banner {
    overflow: hidden;
    background-color: #fefefe;
    margin-top: -10px;

    &.disabled {
      opacity: 0.7;
    }
  }

  .cp-card-body {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 12px 16px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);

    &.disabled {
      .cp-card-content {
        color: #cacaca;

        .content-title {
          color: #cacaca;

          .highlight {
            color: #cacaca;
          }
        }

        .content-expire {
          color: #cacaca;
        }
      }
    }

    .cp-card-content {
      min-height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-size: clamp(12px, 3.74vw, 14px);
      color: $cp-primary-darkgray;

      .content-title {
        font-size: 16px;
        font-size: $s14-16;
        font-weight: 400;
        color: $cp-primary-black;

        .highlight {
          color: $cp-primary-1;
        }
      }

      .content-expire {
        text-align: right;
        font-size: 14px;
        font-size: clamp(12px, 3.74vw, 14px);
        color: $cp-primary-gray;
      }
    }

    .cp-card-note {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-size: clamp(12px, 3.74vw, 14px);
      color: $cp-primary-darkgray;
    }
  }
}

.expire {
  &-success {
    color: #00b9b9;
  }

  &-warning {
    color: #f08700;
  }

  &-danger {
    color: #db0000;
  }
}

.note {
  &-light {
    color: $cp-primary-gray;
  }

  &-success {
    color: #00b9b9;

    &.disabled {
      color: #cacaca;
    }
  }

  &-warning {
    color: #f08700;

    &.disabled {
      color: #cacaca;
    }
  }

  &-danger {
    color: #db0000;

    &.disabled {
      color: #cacaca;
    }
  }

  &-progress {
    width: 25%;

    .progress {
      height: 8px;
      border-radius: 4px;
      background-color: #f2f2f2;

      .progress-bar {
        border-radius: 4px;
        background-color: #e0e0e0;
      }

      &.progress-warning {
        background-color: #feeee3;

        .progress-bar {
          border-radius: 4px;
          background-color: #f08700;
        }
      }
    }
  }

  &-complete {
    color: #70b412;

    &.disabled {
      color: #cacaca;
    }
  }
}

/* Start customize swiper */
.swiper {
  .pagination-horizontal {
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  .bullet {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    display: inline-block;
    border-radius: 50%;
    background: #ccdfff;

    &.active {
      background: $cp-primary-1;
    }
  }
}

/* End customize swiper */

/* Start customize sweetalert2 */
.swal2-container {
  &.swal2-center {
    > .swal2-popup {
      border-radius: 16px;

      .cp-close {
        color: $cp-primary-gray;
      }

      .cp-image {
        margin: 0 auto;
        margin-top: -24px;
        z-index: 2;
        height: 144px;
      }

      .cp-title {
        font-weight: 600;
        font-size: 20px;
        font-size: $s18-20;
        color: $cp-primary-black;
        white-space: pre;
        outline: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        appearance: none !important;
        -webkit-appearance: none !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &.mt-1 {
          margin-top: 0.25rem;
        }

        &.mt-2 {
          margin-top: 0.5rem;
        }

        &.mt-3 {
          margin-top: 1rem;
        }

        &.mt-9rem {
          margin-top: 9rem;
        }

        &.fs-24 {
          font-size: 24px;
        }

        &.ml-8 {
          margin-left: 8px;
        }

        &.mr-8 {
          margin-right: 8px;
        }
      }

      .cp-title-2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #ffffff;
      }

      .cp-description {
        white-space: balance;

        &-reward {
          font-weight: 400;
          font-size: 20px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }

        &.subtitle-popup {
          color: #212121;
        }
      }
      .cp-description-2 {
        font-weight: 400;
        font-size: 16px;
        color: $cp-primary-black;
      }

      .cp-html-container {
        font-size: 16px !important;
        font-size: $s14-16 !important;
        height: 100%;
        color: $cp-primary-darkgray;
        // position: relative;
        // margin: 16px 16px 3px 16px;

        .cp-item-image {
          position: absolute;
          top: -25px;
          left: 0;
          right: 0;
          z-index: 1;

          img {
            border-radius: 13px;
          }
        }

        .cp-bg-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        .cp-base-image {
          position: absolute;
          top: -24px;
          left: calc(50% - 240px / 2);
          margin-left: auto;
          margin-right: auto;
          z-index: 1;
        }

        .cp-playcard-image {
          @media (max-width: 531px) {
            margin-bottom: calc((100vw * (-28)) / 375);
          }
          @media (min-width: 532px) {
            margin-bottom: -28px;
          }
          img {
            width: 100%;
            height: auto;
            vertical-align: middle;
            border-radius: 16px;
          }
        }

        .cp-playcard-box {
          position: relative;
          border-radius: 16px;
          background-color: white;
          padding: 16px;
        }

        .cp-playcard-close {
          background: transparent;
          border: none;
          padding: 0;
          z-index: 1;
          position: absolute;
          @media (max-width: 531px) {
            top: calc(100vw * (22 / 375));
            right: calc(100vw * (22 / 375));
          }
          @media (min-width: 532px) {
            top: 25px;
            right: 25px;
          }
        }
      }

      .cp-playcard-container {
        margin: 6px;
      }

      .cp-actions {
        display: block !important;
        margin: 8px auto 0 !important;
        padding: 0 16px;
        width: 100%;

        .cp-btn-primary {
          padding: 0.5835em 1.1em;
          font-size: 18px;
          font-size: clamp(16px, 4.8vw, 18px);
          font-weight: 600;
          border-radius: 8px;
          background-color: $cp-primary-linear-gradient-1;

          &.cp-btn-block {
            width: 100%;
            height: 100%;
            margin: 0;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .cp-btn-primary-spend {
          padding: 0.5835em 1.1em;
          font-size: 18px;
          font-size: clamp(16px, 4.8vw, 18px);
          font-weight: 600;
          border-radius: 8px;
          background-color: $cp-primary-spend-linear-gradient-1;

          &.cp-btn-block {
            width: 100%;
            height: 100%;
            margin: 0;
          }

          &:focus {
            box-shadow: none;
          }
        }

        .cp-btn-link {
          padding: 0;
          margin-top: 8px;
          margin-bottom: 0;
          text-decoration: underline;
          color: $cp-primary-1;
          background: transparent;

          &.cp-btn-block {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .swal2-styled.swal2-cancel {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    font-size: $s14-16;
    line-height: 0;
    text-align: center;
    color: $cp-primary-1;
    background-color: transparent;
    background-image: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;

    &-blue {
      width: 100%;
      padding: 0;
      margin: 16px 0 0 0;
      display: inline-block;
      line-height: 24px;
      box-shadow: none;
      color: #2495ff;
    }

    &-white {
      color: #f4faff;
      height: 48px;
      border: 1px solid #fff;
      width: 12rem;
      border-radius: 24px;
      margin: -5px;
      font-size: 18px;
    }
  }

  .cp-btn-primary-border {
    background: #ffffff;
    height: 65px;
    padding: 5px;
    border-radius: 24px;
    display: flex;
    width: 12rem;
    margin: 48px auto auto auto;
  }

  .cp-reward-image {
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;

    #cp-reward-image {
      height: 0;
      width: 0;
      transition: all 0.8s ease-in-out;

      &.rendered {
        width: 144px;
        height: 144px;
      }
    }

    img {
      border-radius: 16px;
    }
  }

  .lottie-loop {
    &.rendered {
      width: 100% !important;
    }
  }
}

/* End customize sweetalert2 */

.panel {
  z-index: 999;

  &.container {
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 16px 16px 8px;
    position: relative;
  }
}

.campaign-highlight {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  font-size: clamp(16px, 4.8vw, 18px);
  line-height: 27px;
  color: $cp-primary-black;
}

.campaign-description {
  font-weight: 400;
  font-size: 16px;
  font-size: $s14-16;
  line-height: 24px;
  color: $cp-primary-darkgray;
  word-wrap: break-word;
}

.campaign-expire-date {
  font-weight: 400;
  font-size: 14px;
  font-size: clamp(12px, 3.74vw, 14px);
  line-height: 21px;
  color: $cp-primary-gray;
  display: flex;
  align-items: center;
}

.campaign-status {
  font-weight: 400;
  font-size: 14px;
  font-size: clamp(12px, 3.74vw, 14px);
  line-height: 21px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cp-font-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-size: $s14-16;
  color: $cp-primary-darkgray;
}

.cp-font-header {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-size: $s14-16;
  color: $cp-primary-black;
}

a {
  font-weight: 600;
  font-size: 16px;
  font-size: $s14-16;
  line-height: 24px;
  text-decoration-line: underline;
  color: $cp-primary-1;
  border: none;
  background-color: transparent;
}

.custom-top {
  margin-top: 0;
  top: calc((100vw * 88) / 194);
  position: absolute !important;
  width: 100%;

  &.is-loading {
    position: static !important;
    margin-top: -9vw !important;
  }
}

.history-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-size: $s18-20;
  line-height: 30px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.history-desc {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-size: $s14-16;
  line-height: 24px;
  color: $cp-primary-black;
}

.history-coupon {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-size: $s14-16;
  line-height: 24px;
  color: $cp-primary-1;
  display: flex;
  float: right;
  margin-top: -24px;

  img {
    margin-top: 5px;
    z-index: 1;
  }
}

.history-description {
  font-weight: 400;
  font-size: 16px;
  font-size: $s14-16;
  line-height: 24px;
  color: $cp-primary-gray;
  word-wrap: break-word;
}

.grayscale {
  &-7 {
    filter: grayscale(0.7);
  }

  &-1 {
    filter: grayscale(1);
  }
}

.text-ellipsis-inline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  &-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  &-4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}

.cp-btn-primary:active {
  background: linear-gradient(180deg, #22d0d7 0%, #03abab 87.12%) !important;
}

.mb-6px {
  margin-bottom: 6px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.mx-reward {
  margin-top: 20px;
  margin-bottom: 13px;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}

.text-over-image {
  position: absolute;
  top: 27px;
  left: 16px;
  right: 16px;
  font-size: 18px;
  font-size: clamp(16px, 4.8vw, 18px);
  font-weight: 400;
}

.fw {
  &-600 {
    font-weight: 600;
  }
}

a,
img {
  -webkit-touch-callout: none;
}

.customer-swiper-container {
  .swiper-slide {
    width: 134px !important;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 0;
  right: 16px;
  z-index: 10;
  .icon {
    z-index: 10;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    background-color: $cp-primary-1;
    opacity: 0.85;
    width: 40px;
    height: 40px;
    img {
      width: 24px;
      height: 24px;
      margin: 8px;
    }
  }
}

:focus-visible {
  outline: none !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
